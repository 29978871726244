import React from 'react';

const BarcodePreview = () => {
  return (
    <div className='w-100' style={{height:"486px"}}>
      <div className="web-qr-input-box bg-gray-300 p-5">
        <input className="w-100 form-control" placeholder="enter website URL" type="text" />
      </div>
      <iframe id="web-view" className="h-100" src="https://divsly.com"></iframe>
    </div>
  );
};

export default BarcodePreview;
