import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import FloatingBtn from "./floatingbutton/floatingBtn";

const BusinessPreview = ({ formData }) => {
  useEffect(() => {
    console.log("FORM:-> ", formData);
  }, [formData]);

  const convertToAmPm = (time) => {
    if (!time) return "";
    let [hour, minute] = time.split(":");
    hour = parseInt(hour, 10);

    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour}:${minute} ${ampm}`;
  };
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDayIndex = new Date().getDay();
  const currentDay = daysOfWeek[currentDayIndex];

  const checkIfOpen = (day, start, end) => {
    if (!start || !end) return false;

    const [startHour, startMinute] = start.split(":");
    const [endHour, endMinute] = end.split(":");
    const currentTime = new Date();
    const startTime = new Date();
    const endTime = new Date();

    startTime.setHours(parseInt(startHour, 10), parseInt(startMinute, 10));
    endTime.setHours(parseInt(endHour, 10), parseInt(endMinute, 10));

    return currentTime >= startTime && currentTime <= endTime;
  };

  const buisnessPageItems = useMemo(() => [
    {
      text: "Call",
      href: `tel${formData?.aboutPhone}`,
      icon: "fa-solid fa-phone",
      onClick: () => {console.log("formData?.aboutPhone", formData?.aboutPhone) },
    },
    {
      text: "Email",
      href: `mailto:${formData?.aboutEmail}`,
      icon: "fa-solid fa-envelope",
      onClick: () => { },
    },
    {
      text: "Website",
      href: formData?.aboutWebsite || "#",
      icon: "fa-solid fa-globe",
      onClick: () => { },
    },
    {
      text: "Share Page",
      icon: "fa-solid fa-share-nodes",
      onClick: () => { },
    },
  ], [formData]);

  return (
    <div className="position-relative onhvr-scroll-y">
      <div className="w-100 mx-auto scroll-content" style={{
        height: "580px",
        maxWidth: "900px",
          background: formData?.gradient
                ? `linear-gradient(to top, ${formData?.primaryColor || "#000"
                }, ${formData?.gradientColor})`
                : formData?.primaryColor || "#000",
      }}>
        <div className="qr-p-v-card" >
          <div
            style={{
              height: "450px",
                backgroundImage: `url(${
                  formData?.image ||
                  "https://images.pexels.com/photos/1647919/pexels-photo-1647919.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            className={` d-flex justify-content-center align-items-start py-0 flex-column`}
          >
            <div className="d-flex px-5 justify-content-end h-100 py-5 align-items-start flex-column text-start"
            style={{
              background: "rgb(255,255,255)",
              background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.41780462184873945) 30%, ${formData?.primaryColor} 90%,  ${formData?.primaryColor} 100%)`
            }}
            >

            <h1 className="fs-2 mb-3 fw-bold text-white">
              {formData?.company || "Company"}
            </h1>
              <h2 className="fs-3 fw-bold text-white">
                {formData?.headline || "Heading Text"}
              </h2>
              <p className="fs-6 text-gray-100">
                {formData?.summary ||
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, tempora!"}
              </p>
              <a
                href={formData?.buttonUrl || "#"}
                style={{ background: formData?.buttonColor || "white" }}
                className="btn btn-outline bg-transparent text-white border border-white my-3 rounded-1"
              >
                {formData?.buttonText || "View Menu"}
              </a>
            </div>
          </div>
          <div className="px-5 pb-15">

          <div className="biz-card">
          <div className="biz-card-header border-0 justify-content-center pb-0">
                <p className="fs-2 fw-bold tex-center mb-0" style={{ lineHeight: "1.3" }}>About Us</p>
              </div>
              <div className="biz-card-body">
                <p className="text-gray-400 text-center mb-0" style={{ gap: "10px" }}>
                  {formData?.about || "lorem ipsum"}
                </p>
                </div>
              </div>

            <div
              className="biz-card"
            >
              <div className="biz-card-header">
                <span className="h-40px w-40px d-flex justify-content-center align-items-center rounded-circle" style={{backgroundColor: formData?.primaryColor || "#000",}}><i className="fa-solid fa-clock text-light fs-3"></i></span>
                <p className="fs-3 mb-0" style={{ lineHeight: "1.3" }}>Opening Hours</p>
              </div>
              <div className="biz-card-body">
                {daysOfWeek.map((day, index) => {
                  const isToday = day === currentDay;
                  const openField = `${day.toLowerCase()}Open`;
                  const startField = `${day.toLowerCase()}Start`;
                  const endField = `${day.toLowerCase()}End`;

                  return (
                    <div key={day} className="d-flex justify-content-between w-100">
                      <p className={isToday ? "fw-bold" : ""}>
                        {day} {isToday && "(Today)"}
                      </p>
                      {formData?.[openField] ? (
                        <p className="text-gray-400">
                          {convertToAmPm(formData?.[startField])} -{" "}
                          {convertToAmPm(formData?.[endField])}
                        </p>
                      ) : (
                        <p className="d-block text-danger">Closed</p>
                      )}
                    </div>
                  );
                })}
                <p className="d-none w-100">
                  {checkIfOpen(
                    currentDay,
                    formData?.[`${currentDay.toLowerCase()}Start`],
                    formData[`${currentDay.toLowerCase()}End`]
                  )
                    ? "Open Now"
                    : "Closed"}
                </p>
              </div>
            </div>
            <div
              className="biz-card"
            >
              <div className="biz-card-header">
              <span className="h-40px w-40px d-flex justify-content-center align-items-center rounded-circle" style={{backgroundColor: formData?.primaryColor || "#000",}}><i className="fa-solid fa-location-dot text-light fs-3"></i></span>
                <p className="fs-3 mb-0" style={{ lineHeight: "1.3" }}>Location</p>
              </div>
              <div className="biz-card-body">
                <p className="text-gray-400 mb-0">
                  {`${formData?.street||"20 Cooper Square"}, ${formData?.city||
                    "New York"
                  }, ${formData?.country||"USA"}, ${formData?.postalCode||"NY 10003"} ` ||
                    "Mission Street 526, San Francisco, CA, 94105, United States"}
                </p>
                <a href={formData?.location} target="_blank" rel="noreferrer"  className="btn rounded-1 fs-7 mt-5 px-3 py-2 text-light" style={{backgroundColor: formData?.primaryColor || "#000",}}>
                  show on map
                </a>
              </div>
            </div>
            <div
              className="biz-card"
            >

              <div className="biz-card-header">
              <span className="h-40px w-40px d-flex justify-content-center align-items-center rounded-circle" style={{backgroundColor: formData?.primaryColor || "#000",}}><i className="fa-solid fa-user text-light fs-3"></i></span>
                <p className="fs-3 mb-0" style={{ lineHeight: "1.3" }}>Contact</p>
              </div>

              <div className="biz-card-body">
              <div className="mb-5">
                <span className="fs-4">Name</span>
                  <br />
                <p className="text-gray-400 mb-0" style={{ gap: "10px" }}>
                  {formData?.aboutName || "John"}
                </p>
              </div>
              <div className="mb-5">
                  <span className="fs-4">Phone</span>
                  <br />
                <p className="text-gray-400 mb-0" style={{ gap: "10px" }}>
                  {formData?.aboutPhone || "+1 9999999999"}
                </p>
              </div>
              <div>
                  <span className="fs-4">Email</span>
                  <br />
                <p className="text-gray-400 mb-0" style={{ gap: "10px" }}>
                  {formData?.aboutEmail || "www.abcdemail.com"}
                </p>
              </div>
              </div>

              </div>


            <div
              className="biz-card"
            >

              <div className="biz-card-body">
                  <span className="fs-4">Website</span>
                  <br />
                <p className="text-gray-400 mb-0" style={{ gap: "10px" }}>
                  {formData?.website || "www.abcdemail.com"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingBtn type={"businessPage"} items={buisnessPageItems} />
    </div>
  );
};

export default BusinessPreview;
