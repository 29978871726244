import React from 'react'

const IconFrame3 = ({ primary,fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
   <svg width={width || 1450} height={height || 2103} viewBox="0 0 1450 2103" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect y="653" width={1450} height={1450} rx="114" fill={primary || "#0E1B21"} />
  <rect x="63" y="716" width={1324} height={1324} rx="70" fill="white" />
  <rect width={1450} height={404} rx="80" fill={primary || "#0E1B21"} />
  <path d="M725 581L979.611 401.75H470.389L725 581Z" fill={primary || "#0E1B21"} />
  <text
    fill={textColor || "white"}
    fontFamily="Arial"
    fontSize={fontSize || 212}
    fontWeight="bold"
    x="50%"
    y="271.996"
    textAnchor="middle"
  >
    {text}
  </text>
  <image href={url} x="90" y="750" width="1274" height="1274" />
</svg>

    </>
  )
}

export default IconFrame3