import { createContext, useContext, useEffect, useState } from "react";
import { AuthContxt } from "../../store/authContxt";
import QrCodesAPI from "../../services/http/qrCodes";
import { SvgIcons } from "./create-qr-code/frames-svg";

const filtersDefaultState = {
  starred: false,
  sort: "",
  tags: [],
};

export const QrCodeContxt = createContext(null);

const QrCodeContxtProvider = (props) => {
  const { fetchQuota } = useContext(AuthContxt);

  const [qrCodes, setQrCodes] = useState([]);
  const [tags, setTags] = useState([]);
  const [render, setRender] = useState(false);
  

  const [loading, setLoading] = useState(true);
  const [notQrScreen, setNotQrScreen] = useState("");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(filtersDefaultState);

  const tagsHandler = (links) => {
    const allTags =
      links &&
      links?.length > 0 &&
      links?.reduce((tags, item) => {
        if (item.tags) {
          const itemTags = item.tags
            .split(",")
            .map((tag) => tag.trim())
            .filter((tag) => tag !== "");
          tags.push(...itemTags);
        }
        let uniqueTagsSet = new Set(tags);
        let uniqueTagsArray = Array.from(uniqueTagsSet);
        return uniqueTagsArray;
      }, []);

    setTags(allTags);
  };

  const fetchFilteredData = (filters) => {
    try {
      const { starred, ...rest } = filters;
      const auth_token = localStorage.getItem("auth_token");
      QrCodesAPI.filteredQrCodes(auth_token, {
        ...rest,
        ...(starred ? { starred } : {}),
        tags: filters.tags.join(","),
      })
        .then(({ data }) => {
          if (data?.success) {
            debugger;
          
            // If data.resultData is an array, you might want to handle it accordingly
            const resultDataArray = data.resultData; // Assuming this is an array
          
            // If you need to process each item in the array
            const newDataArray = resultDataArray.map((item) => {
              return {
                ...item,
                frame: item.frame
                  ? SvgIcons.find((q) => q.type === item.frame)
                  : item.frame,
                data: `${item.brandedDomain}/${item.slashTag}?r=qr`,
              };
            });
          
            // Update the state with the new array
            setQrCodes(newDataArray);
          
            // Check if the length of the array is less than 1
            if (resultDataArray.length < 1) {
              setNotQrScreen("searchFound");
            }
          }
          
        })
        .catch((error) => {
          console.log("Error while fetching qr codes: ", error);
        });
    } catch (e) {
      console.log("Error while fetching filtered data : ", e.message);
    }
  };

  const deleteQrCode = (id) => {
    const auth_token = localStorage.getItem("auth_token");
    QrCodesAPI.deleteQrCode(auth_token, id)
      .then(async ({ data }) => {
        if (data?.success) {
          const filteredQrCodes = qrCodes.filter((item) => item.id !== id);
          if (
            filters?.tags?.length > 0 ||
            filters?.sort !== "" ||
            search !== ""
          ) {
            await getQrCodes();
            setSearch("");
            setFilters(filtersDefaultState);
          } else {
            tagsHandler(filteredQrCodes);
            filteredQrCodes.length === 0 && setNotQrScreen("notFound");
            setQrCodes(filteredQrCodes);
          }
          await fetchQuota();
        }
      })
      .catch(({ error }) => {
        console.log("Error while deleting qr code: ", error);
      });
  };

  const getQrCodes = async () => {

    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    QrCodesAPI.getQrCodes(auth_token)
      .then(({ data }) => {
        if (data?.success) {
          const newData = data?.resultData?.map((e) => {
            return {
              ...e,
              frame: e.frame ?SvgIcons.find((q) => q.type === e.frame):  e.frame ,
            };
          });
          console.log(SvgIcons);
          setQrCodes(newData);
          tagsHandler(newData);
          newData?.length < 1 && setNotQrScreen("notFound");
        }
      })
      .catch((error) => {
        console.log("Error while fetching qr codes: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getQrCodes();
  }, []);

  return (
    <QrCodeContxt.Provider
      value={{
        loading,
        tags,
        tagsHandler,
        qrCodes,
        setQrCodes,
        notQrScreen,
        setNotQrScreen,
        deleteQrCode,
        filters,
        setFilters,
        fetchFilteredData,render, setRender,
        search,
        setSearch,
      }}
    >
      {props.children}
    </QrCodeContxt.Provider>
  );
};

export default QrCodeContxtProvider;
