import React from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';

const BarcodeForm = ({ formData, setFormData }) => {

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <Form>
      {/* GTIN Field */}
      <FormGroup>
        <Label for="globalTradeItemNumber">Global Trade Item Number (01):</Label>
        <Input
          type="text"
          id="globalTradeItemNumber"
          value={formData?.globalTradeItemNumber || ''}
          onChange={handleInputChange}
          placeholder="Enter GTIN"
        />
      </FormGroup>

      {/* Destination URL Field */}
      <FormGroup>
        <Label for="destinationUrl">Destination URL:</Label>
        <Input
          type="url"
          id="destinationUrl"
          value={formData?.destinationUrl || ''}
          onChange={handleInputChange}
          placeholder="Enter Destination URL"
        />
      </FormGroup>
    </Form>
  );
};

export default BarcodeForm;
