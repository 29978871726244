import { Card, CardBody, Row, Spinner } from "reactstrap";
import QrCodesHeader from "./header";
import QrCode from "./qrCode";
import NotSearchFound from "../common/emptyScreens/notSearchFound";
import NotFound from "../common/emptyScreens/notFound";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { QrCodeContxt } from "./qrCodeContxt";
import { AuthContxt } from "../../store/authContxt";

const QrCodes = () => {
  const { loading, qrCodes, notQrScreen } = useContext(QrCodeContxt);
  const { quota } = useContext(AuthContxt);
  const isLimitReached = quota?.qrCode === 0;
  const navigate = useNavigate();
  const notQrScreenMapper = {
    searchFound: <NotSearchFound type={"qr"} />,
    notFound: (
      <NotFound
        isLimitReached={isLimitReached}
        handler={() => {
          navigate("/list-qr-codes");
        }}
        type={"qr"}
      />
    ),
  };

  return (
    <>
      <div className="d-flex flex-column w-100 flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid pe-lg-0 me-lg-5 mt-lg-5 mt-0 position-relative">
            <div className="app-main flex-column flex-row-fluid">
              <Card className="card card-flush mb-sm-60px rounded-sm-0">
                <QrCodesHeader count={qrCodes?.length} />
                <CardBody className="card-body px-6 pe-lg-6 pt-0 overflow-x-auto  card-body">
                  {loading ? (
                    <div
                      style={{
                        height: "70vh",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <Row className="qr-code-list-container px-0 w-100 mx-auto">
                      {qrCodes && qrCodes?.length > 0 ? (
                        qrCodes?.map((qrCode, index) => {
                          return (
                            <QrCode
                              key={qrCode?.slashTag + index}
                              index={index}
                              qrCode={qrCode}
                            />
                          );
                        })
                      ) : (
                        <tr
                          style={{
                            display: "flex",
                          }}
                        >
                          <td
                            className="border-0 position-relative w-100"
                            colSpan={"8"}
                          >
                            {notQrScreenMapper[notQrScreen]}
                          </td>
                        </tr>
                      )}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrCodes;
