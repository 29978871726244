import React from "react";

const ImagesShower = ({ formData,setFormData }) => {
    const handleImageRemove = (index) => {
        debugger
        const updatedList = formData?.imageList?.filter((ele, i) => i !== index);
        setFormData((prev) => {
          return { ...prev, imageList: updatedList };
        });
      };
  return (
    <>
      <div className="d-flex flex-wrap gap-5">
        {formData?.imageList?.map((e,key) => {
          return (

            <div className="position-relative w-100px" key={e}>
                <i
              onClick={() => {
                handleImageRemove(key);
              }}
              style={{ height: "32px", top: "10px", right: "10px" }}
              className="fa-solid fa-circle-xmark  fs-3 text-danger position-absolute"
            ></i>
              <img style={{width:"150px"}} src={e} alt="Image" />
            </div>

          );
        })}
      </div>
    </>
  );
};

export default ImagesShower;
