import React from 'react'

const IconFrame5 = ({ url, width, height }) => {
  return (
    <>
  <svg width={width || 2048} height={height || 2048} viewBox="0 0 1759 2419" fill="none" xmlns="http://www.w3.org/2000/svg">
 
  <image href={url} x="0" y="0" width="2048" height="2048" />
</svg>

 

    </>
  )
}

export default IconFrame5