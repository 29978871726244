import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>

<svg width={width || 2379} height={height || 1802} viewBox="0 0 2379 1802" fill="none" xmlns="http://www.w3.org/2000/svg">
<text fill={textColor || "black"} xmlSpace="preserve" style={{whiteSpace: "pre"}} fontFamily="Arial" fontSize={fontSize || 223} 
fontWeight="bold" letterSpacing="0em"><tspan x="53%" textAnchor="middle" y="1737.31">{text}</tspan></text>
<path d="M594 150C594 122.386 616.386 100 644 100H1860C1887.61 100 1910 122.386 1910 150V1366C1910 1393.61 1887.61 1416 1860 1416H644C616.386 1416 594 1393.61 594 1366V150Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M644 0H1860C1942.84 0 2010 67.1573 2010 150V1366C2010 1448.84 1942.84 1516 1860 1516H644C561.157 1516 494 1448.84 494 1366V150C494 67.1572 561.157 0 644 0ZM644 100C616.386 100 594 122.386 594 150V1366C594 1393.61 616.386 1416 644 1416H1860C1887.61 1416 1910 1393.61 1910 1366V150C1910 122.386 1887.61 100 1860 100H644Z" fill={secondary|| "#6C7480"}/>
<path d="M2339.63 775.247V17.0439C2339.63 9.72858 2334.96 3.22646 2328.01 0.889811C2321.07 -1.44315 2313.41 0.915568 2308.98 6.7443C2214.26 131.499 2163 283.767 2163 440.33V717.703C2163 749.514 2188.82 775.306 2220.67 775.306H2244.72C2221.02 1019.8 2205.35 1361.75 2205.35 1401.79C2205.35 1464.87 2244.23 1516 2292.18 1516C2340.13 1516 2379 1464.86 2379 1401.79C2379 1361.74 2363.34 1019.74 2339.63 775.247Z" fill={primary|| "black"}/>
<path d="M263.271 583.144C313.457 552.71 347 497.528 347 434.496L322.142 27.6024C321.191 12.0927 308.35 0 292.827 0C276.038 0 262.663 14.0805 263.498 30.8576L279.663 357.19C279.663 375.535 264.806 390.401 246.489 390.401C228.169 390.401 213.316 375.527 213.316 357.19L205.239 30.9925C204.811 13.753 190.731 0.00385027 173.504 0.00385027C156.276 0.00385027 142.2 13.7569 141.769 30.9925L133.692 357.19C133.692 375.535 118.835 390.401 100.518 390.401C82.1978 390.401 67.3444 375.527 67.3444 357.19L83.51 30.8576C84.3411 14.0728 70.9577 0 54.1803 0C38.6573 0 25.8164 12.0927 24.8698 27.6024L0 434.499C0 497.532 33.5394 552.71 83.7293 583.148C116.391 602.953 134.6 639.982 130.391 677.982C102.057 933.619 82.8173 1350.97 82.8173 1396.43C82.8173 1462.47 123.418 1516 173.5 1516C223.582 1516 264.183 1462.46 264.183 1396.43C264.183 1350.97 244.939 933.619 216.609 677.982C212.396 640.001 230.624 602.941 263.271 583.144Z" fill={primary|| "black"}/>

  
  {/* Image element */}
  <image href={url} x="740" y="246" width="1024" height="1024" />
</svg>


 

    </>
  )
}

export default IconFrame5