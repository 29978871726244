import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import QrCodeDetailsAnalytics from "./edit-qr-code/qrDetailsAnalytics";
import EditQrCode from "./edit-qr-code";
import DeleteModal from "../../modals/deleteModal";
import { createUrl } from "../../utils";
import dateFormate from "../../utils/dateFormat";
import imageDownloadHandler from "../../utils/imageDownLoadHandler";
import imageCopyHandler from "../../utils/imageCopyHandler";
import { QrCodeContxt } from "./qrCodeContxt";
import Frames from "./create-qr-code/frames-svg";

const QrCode = ({ qrCode,index }) => {
  const navigate = useNavigate();
  const { deleteQrCode } = useContext(QrCodeContxt)
  const [analyticsModal, setAnalyticsModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const {
    title = "",
    destinationUrl = "",
    createdAt,
    brandedDomain = "",
    slashTag = "",
    id,
  } = qrCode;

  // Update QR Code
  const [editQrCode, setEditQrCode] = useState(false);
  const [edittedData, setEdittedData] = useState({});

  // Copy QR Code
  const [isCopied, setIsCopied] = useState(false);
  const [copyLoader, setCopyLoader  ] = useState(false);
const svgRef=useRef()
  const editHandler = () => {
    setEditQrCode(true);
    setEdittedData(qrCode);
  };

  const deleteQrCodeHandler = async () => {
    setDeleteModal(false);
    setDeleteLoading(true);
    await deleteQrCode(id);
    setDeleteLoading(false);
  };

  const copyHandler = async () => {
    setCopyLoader(true)

    imageCopyHandler({...qrCode,height:"100%",width:"100%"},()=>{
      setCopyLoader(false)
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    });
   
  };

  return (
    <>
      <Col md={6} className="px-lg-4 px-0 pt-0 pb-8">
        <div className="rounded-1 p-5 pt-lg-5 pt-2 border border-gray-300 mobile-hide">
          <Row>
            <Col md={4} className="p-lg-0 px-lg-4 p-1">
              <div className={`qr${index}`} ref={svgRef}>
                {
  
   (qrCode?.frame ? 
   <Frames type={qrCode?.frame.type} minFontSize={qrCode?.frame.minFontSize} maxFontSize={qrCode?.frame.maxFontSize} width="100%" height={"100%"} url={qrCode?.qr}  primary={qrCode?.primary} textColor={qrCode?.textColor} secondary={qrCode?.secondary} text={qrCode?.text} />
   :
   <img
   src={qrCode?.qr}
   alt="Qr code"
   className="w-lg-100 w-100px border border-gray-250 mx-auto"
 />)
}

              </div>
            </Col>
            <Col md={8} className="d-flex align-items-center pe-5">
              <div className="w-100 d-flex flex-column justify-content-between h-100 py-lg-5 position-relative">
                <div>
                  <h3 className="fs-lg-20px fw-bold text-wrap-oneline">
                    {title}
                  </h3>
                  <p className="text-wrap-oneline mb-2 fs-5 fw-semibold">
                    {destinationUrl}
                  </p>
                  <Link
                    target="_blank"
                    to={`https://${createUrl(brandedDomain, slashTag)}`}
                    className="fs-5 text-info fw-semibold"
                  >
                    {createUrl(brandedDomain, slashTag)}
                  </Link>
                  <div className="mt-2 mb-0 d-flex">
                    <p
                      className="mb-0 cursor-pointer"
                      id="link_date"
                      data-kt-initialized="1"
                    >
                      <i className="fa-regular fa-calendar me-2"></i>
                      {dateFormate(createdAt)}
                    </p>
                  </div>
                </div>

                <div className="pt-5">
                  <button
                    type="button"
                    onClick={() => navigate(`/create-qr-code/${id}`)}
                    className="btn btn-icon rounded-1 bg-light-info me-4"
                  >
                    <i className="fa-regular fa-pen-to-square text-info fs-3"></i>
                  </button>
                  <button
                    type="button"
                    onClick={copyHandler}
                    className="btn btn-icon rounded-1 bg-light-info me-4"
                  >
                   {
                    copyLoader ?<Spinner/> : <>
                     {isCopied ? (
                      <i class="fa-regular fa-check fs-2 text-green-800 cursor-pointer"></i>
                    ) : (
                      <i className="fa-regular fa-clone text-info fs-3"></i>
                    )}</>
                   }
                  </button>
                  <button
                    type="button"
                    onClick={editHandler}
                    className="btn btn-icon rounded-1 bg-light-info me-4"
                  >
                    <i class="fa-regular fa-chart-line text-info fs-3"></i>
                  </button>
                  <button
                    disabled={deleteLoading}
                    onClick={() => setDeleteModal(true)}
                    type="button"
                    className="btn btn-icon rounded-1 bg-light-info me-4"
                  >
                    <i className="fa-regular fa-trash-can text-info fs-3"></i>
                  </button>

                  <UncontrolledDropdown
                    className="d-inline-block"
                    direction="down"
                  >
                    <DropdownToggle className="btn btn-icon bg-light-info d-block rounded-1">
                      <i className="fa-regular fa-download text-info fs-3"></i>
                    </DropdownToggle>
                    <DropdownMenu className="w-100 shadow border border-gray-250 rounded-1">
                      <DropdownItem
                        onClick={() => imageDownloadHandler(svgRef,{...qrCode,width:"100%",height:"100%"}, "png")}
                      >
                        Download PNG
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => imageDownloadHandler(svgRef,{...qrCode,width:"100%",height:"100%"}, "jpg")}
                      >
                        Download JPG
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => imageDownloadHandler(svgRef,{...qrCode,width:"100%",height:"100%"}, "svg")}
                      >
                        Download SVG
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="rounded-1 p-5 pt-lg-5 border border-gray-300 desktop-hide">
          <Row>
            <Col className="col-5 p-0 px-lg-4 ps-3">
              <div>
                <img
                  src={qrCode?.qr}
                  alt="Qr code"
                  className="w-100 border border-gray-250 mx-auto"
                />
              </div>
            </Col>
            <Col className="col-7 d-flex align-items-center pe-5">
              <div className="w-100 py-lg-5 position-relative">
                <div>
                  <h3 className="fs-lg-20px fw-bold text-wrap-oneline">
                    {title}
                  </h3>
                  <p className="text-wrap-oneline mb-1 fs-5 fw-semibold">
                    {destinationUrl}
                  </p>
                  <Link
                    target="_blank"
                    to={`https://${createUrl(brandedDomain, slashTag)}`}
                    className="fs-5 text-info fw-semibold"
                  >
                    {createUrl(brandedDomain, slashTag)}
                  </Link>
                  <div className="mt-1 mb-0 d-flex">
                    <p
                      className="mb-0 cursor-pointer"
                      id="link_date"
                      data-kt-initialized="1"
                    >
                      <i className="fa-regular fa-calendar me-2"></i>
                      {dateFormate(createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="pt-5 d-flex gap-5 flex-wrap">
                <button
                  type="button"
                  onClick={() => navigate(`/create-qr-code/${id}`)}
                  className="btn btn-icon rounded-1 bg-light-info"
                >
                  <i className="fa-regular fa-pen-to-square text-info fs-3"></i>
                </button>
                <button
                  type="button"
                  onClick={copyHandler}
                  className="btn btn-icon rounded-1 bg-light-info"
                >
                  {isCopied ? (
                    <i class="fa-regular fa-check fs-2 text-green-800 cursor-pointer"></i>
                  ) : (
                    <i className="fa-regular fa-clone text-info fs-3"></i>
                  )}
                </button>
                <button
                  type="button"
                  onClick={editHandler}
                  className="btn btn-icon rounded-1 bg-light-info"
                >
                  <i class="fa-regular fa-chart-line text-info fs-3"></i>
                </button>
                <button
                  disabled={deleteLoading}
                  onClick={() => setDeleteModal(true)}
                  type="button"
                  className="btn btn-icon rounded-1 bg-light-info"
                >
                  <i className="fa-regular fa-trash-can text-info fs-3"></i>
                </button>

                <UncontrolledDropdown
                  className="d-inline-block"
                  direction="down"
                >
                  <DropdownToggle className="btn btn-icon bg-light-info d-block rounded-1">
                    <i className="fa-regular fa-download text-info fs-3"></i>
                  </DropdownToggle>
                  <DropdownMenu className="w-100 shadow border border-gray-250 rounded-1">
                    <DropdownItem
                      onClick={() => imageDownloadHandler(qrCode, "png")}
                    >
                      Download PNG
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => imageDownloadHandler(qrCode, "jpg")}
                    >
                      Download JPG
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => imageDownloadHandler(qrCode, "svg")}
                    >
                      Download SVG
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </Row>
        </div>
      </Col>

      <EditQrCode
        edittedDataHandler={setEdittedData}
        editQrCode={editQrCode}
        setEditQrCode={setEditQrCode}
        edittedData={edittedData}
      />

      {analyticsModal ? (
        <QrCodeDetailsAnalytics
          shortlink={`https://${brandedDomain}/${slashTag}`}
          modal={analyticsModal}
          toggle={() => setAnalyticsModal(false)}
        />
      ) : null}

      {/* Modals */}
      <DeleteModal
        isOpen={deleteModal}
        modalHandler={() => setDeleteModal(false)}
        leftBtnHandler={deleteQrCodeHandler}
        rightBtnHandler={() => setDeleteModal(false)}
        data={{
          heading: "Delete QR Code",
          description: "Are you sure you want to delete this QR code?",
          leftBtn: "Yes",
          rightBtn: "No",
        }}
      />
    </>
  );
};

export default QrCode;
