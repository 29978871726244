import React, { useEffect } from 'react';

const WebPreview = ({formData}) => {

  return (
    <div className='w-100' style={{height:"486px"}}>
                <div className="web-qr-input-box bg-gray-300 p-5">
                  <input className="w-100 form-control" placeholder={formData?.destinationUrl||"enter website URL" } type="text" />
                </div>
                <iframe id="web-view" className='h-100 w-100'  src={formData?.destinationUrl || "https://divsly.com"}></iframe>
    </div>
  );
};

export default WebPreview;
