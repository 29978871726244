import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Row,
  FormGroup,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import AbstractAPIImage from "../../assets/media/integrations/abstractapi.png";
import mailgunImage from "../../assets/media/integrations/mailgun.png";
import mailersendImage from "../../assets/media/integrations/mailersend.png";
import postmarkImage from "../../assets/media/integrations/postmark.png";
import elasticEmailImage from "../../assets/media/integrations/elasticemail.png";
import sparkpostImage from "../../assets/media/integrations/sparkpost.png";
import mailchimpImage from "../../assets/media/integrations/mailchimp.png";
import mailModoImage from "../../assets/media/integrations/mailmodo.png";
import amazonsesImage from "../../assets/media/integrations/amazonses.png";
import sendGridImage from "../../assets/media/integrations/sendgrid.png";
import sendinBlueImage from "../../assets/media/integrations/sendinblue.png";
import emailEngineImage from "../../assets/media/integrations/emailengine.png";
import twillioImage from "../../assets/media/integrations/twillio.png";
import smtpComImage from "../../assets/media/integrations/smtp.png";
import clicksendImage from "../../assets/media/integrations/clicksend.jpg";
import messagebirdImage from "../../assets/media/integrations/messagebird.svg";
import vonageImage from "../../assets/media/integrations/vonage.svg";
import bulksmsImage from "../../assets/media/integrations/bulksms.png";
import plivoImage from "../../assets/media/integrations/Plivo.png";
import clickatellImage from "../../assets/media/integrations/Clickatell.png";
import truedialogImage from "../../assets/media/integrations/truedialog.svg";
import trumpiaImage from "../../assets/media/integrations/trumpia.png";
import IntegrationsAPI from "../../services/http/integration";
import ErrorNotification from "../../components/common/ErrorNotification";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/ToastService";
const brands = [
  {
    name: "SendGrid",
    smallcase: "sendgrid",
    type: "email",
    image: sendGridImage,
    description: "SendGrid provides cloud-based email services.",
    apiKey: true,
  },
  {
    name: "Mailgun",
    smallcase: "mailgun",
    type: "email",
    image: mailgunImage,
    description: "Mailgun is an email automation service.",
    domain: true,
    apiKey: true,
  },
  {
    name: "Brevo",
    smallcase: "brevo",
    type: "email",
    image: sendinBlueImage,
    description: "Brevo provides marketing automation.",
    apiKey: true,
  },
  {
    name: "MailerSend",
    smallcase: "mailersend",
    type: "email",
    image: mailersendImage,
    description: "MailerSend is an transactional email sending platform",
    domain: true,
    apiKey: true,
  },
  {
    name: "Postmark",
    smallcase: "postmark",
    type: "email",
    image: postmarkImage,
    description: "Postmark is an transactional email sending platform",
    apiKey: true,
  },
  {
    name: "ElasticEmail",
    smallcase: "elasticemail",
    type: "email",
    image: elasticEmailImage,
    description: "Elastic Email is an transactional email sending platform",
    apiKey: true,
  },
  {
    name: "SparkPost",
    smallcase: "sparkpost",
    type: "email",
    image: sparkpostImage,
    description: "SparkPost is an transactional email sending platform",
    apiKey: true,
  },
];

const smsBrands = [
  {
    name: "ClickSend",
    smallcase: "clicksend",
    type: "sms",
    image: clicksendImage,
    description:
      "ClickSend is a cloud-based service that lets you send and receive SMS, Email, Voice, and more.",
    apiKey: true,
    username: true,
  },
  {
    name: "Twilio",
    smallcase: "twilio",
    type: "sms",
    image: twillioImage, // Assuming you already have this image imported
    description:
      "Twilio offers a cloud communications platform for building SMS, Voice & Messaging applications.",
    apiKey: true,
    username: true,
    fromNumber: true,
    apiKeyName: "Auth Token",
    usernameName: "Account Sid",
    fromNumberName: "From Number",
  },
  {
    name: "Vonage",
    smallcase: "vonage",
    type: "sms",
    image: vonageImage,
    description:
      "Vonage provides a platform for sending and receiving SMS messages globally.",
    apiKey: true,
  },
  {
    name: "Bulksms",
    smallcase: "bulksms",
    type: "sms",
    image: bulksmsImage,
    description:
      "Bulksms offers a platform for sending and receiving bulk SMS messages.",
    apiKey: true,
  },
  {
    name: "Plivo",
    smallcase: "plivo",
    type: "sms",
    image: plivoImage,
    description:
      "Plivo provides a cloud communications platform for SMS and voice calls.",
    apiKey: true,
  },
];

const brandMap = [...brands, ...smsBrands].reduce((acc, brand) => {
  acc[brand.name.toLowerCase()] = brand.name;
  return acc;
}, {});

const IntegrationsCard = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [testSuccess, setTestSuccess] = useState(false);
  const [username, setUsername] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [domain, setDomain] = useState("");
  const [fromNumber, setFromNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [brandName, setBrandName] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentIntegrationId, setCurrentIntegrationId] = useState(null);

  useEffect(() => {
    if (activeTab === "1") {
      getAllIntegrations();
    }
  }, [activeTab]);

  const providerImages = {
    abstractapi: AbstractAPIImage,
    mailgun: mailgunImage,
    mailchimp: mailchimpImage,
    mailmodo: mailModoImage,
    amazonses: amazonsesImage,
    sendgrid: sendGridImage,
    brevo: sendinBlueImage,
    emailengine: emailEngineImage,
    smtpcom: smtpComImage,
    mailersend: mailersendImage,
    postmark: postmarkImage,
    elasticemail: elasticEmailImage,
    sparkpost: sparkpostImage,
    clicksend: clicksendImage,
    messagebird: messagebirdImage,
    twilio: twillioImage,
    vonage: vonageImage,
    bulksms: bulksmsImage,
    plivo: plivoImage,
    clickatell: clickatellImage,
    truedialog: truedialogImage,
    trumpia: trumpiaImage,
  };

  const getAllIntegrations = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.getAllIntegrations(auth_token);
      let loadedIntegrations = [];
      if (
        response.data &&
        response.data.integrations &&
        response.data.integrations.length > 0
      ) {
        loadedIntegrations = response.data.integrations.map((integration) => {
          return { ...integration, image: providerImages[integration.name] };
        });
      }
      setIntegrations(loadedIntegrations || []);
    } catch (error) {
      console.error("There was an error fetching all integrations!", error);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setTestSuccess(false);
  };

  const handleActivateClick = (brand) => {
    console.log("brand", brand, integrations);
    if (
      integrations &&
      integrations.length > 0 &&
      integrations.some(
        (integration) =>
          integration.name.toLowerCase() === brand.name.toLowerCase()
      )
    ) {
      showErrorToast(`${brand.name} is already configured`, 3000);
      return;
    }
    setBrandName(brand);
    setUsername("");
    setApiKey("");
    setDomain("");
    setFromNumber("");
    setCurrentIntegrationId(null);
    setIsUpdate(false);
    toggleModal();
  };

  const handleUpdateClick = (integration) => {
    const brand = [...brands, ...smsBrands].find(
      (b) => b.name.toLowerCase() === integration.name.toLowerCase()
    );
    setBrandName(brand);
    setUsername("");
    setApiKey("");
    setDomain("");
    setFromNumber("");
    setIsUpdate(true);
    setCurrentIntegrationId(integration.id);
    toggleModal();
  };

  const handleDeleteClick = async (integrationId) => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      await IntegrationsAPI.deleteIntegration(auth_token, integrationId);
      setIntegrations(
        integrations.filter((integration) => integration.id !== integrationId)
      );
    } catch (error) {
      console.error("Error deleting integration:", error);
    }
  };

  const testConnection = async () => {
    if (
      (brandName.username && username === "") ||
      (brandName.apiKey && apiKey === "") ||
      (brandName.domain && domain === "") ||
      (brandName.fromNumber && fromNumber === "")
    ) {
      return;
    }
    setIsLoading(true);
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.testIntegration(auth_token, {
        type: brandName.type,
        provider: brandName.name.toLowerCase(),
        credentials: { username, apiKey, domain, fromNumber },
      });
      if (response.status === 200) {
        setTestSuccess(true);
        showSuccessToast("Test connection is successful", 2000);
      } else {
        showErrorToast(
          "Test connection is failed. Please check your keys and the access",
          2000
        );
        setTestSuccess(false);
      }
    } catch (error) {
      console.error("Error testing connection:", error);
      showErrorToast("Unexpected error happened", 2000);
      setTestSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (
      (brandName.username && username === "") ||
      (brandName.apiKey && apiKey === "") ||
      (brandName.domain && domain === "") ||
      (brandName.fromNumber && fromNumber === "")
    ) {
      return;
    }
    try {
      const name = brandName.name;
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.saveIntegration(auth_token, {
        type: brandName.type,
        provider: brandName.name.toLowerCase(),
        credentials: { username, apiKey, domain, fromNumber },
      });
      // setIntegrations([
      //   ...integrations,
      //   { ...response.data, image: providerImages[response.data.name] },
      // ]);
      toggleModal();
      showSuccessToast(`${name} saved successfully`, 2000);
    } catch (error) {
      console.error("Error saving integration:", error);
      showErrorToast("Something went wrong!", 5000);
    }
  };

  const handleUpdate = async () => {
    if (
      (brandName.username && username === "") ||
      (brandName.apiKey && apiKey === "") ||
      (brandName.domain && domain === "") ||
      (brandName.fromNumber && fromNumber === "")
    ) {
      return;
    }
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.updateIntegration(
        auth_token,
        currentIntegrationId,
        {
          type: brandName.type,
          name: brandName.name.toLowerCase(),
          credentials: { username, apiKey, domain, fromNumber },
        }
      );
      setIntegrations(
        integrations.map((integration) =>
          integration.id === currentIntegrationId
            ? { ...response.data, image: providerImages[response.data.name] }
            : integration
        )
      );
      toggleModal();
    } catch (error) {
      console.error("Error updating integration:", error);
    }
  };

  return (
    <div className="container mt-5">
      <Nav tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            My Integrations
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            Email Integrations
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggleTab("3");
            }}
          >
            SMS Integrations
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="mt-5" activeTab={activeTab}>
        <TabPane tabId="1">
          {integrations.filter((integration) => integration.type === "email")
            .length > 0 && (
            <>
              <h3 className="mb-4 text-center">Email Integrations</h3>
              <div className="row">
                {integrations
                  .filter((integration) => integration.type === "email")
                  .map((integration, index) => (
                    <div key={integration.id} className="col-md-4 col-12 mb-4">
                      <Card className="border-gray-250 border rounded-2 p-3">
                        <div className="d-flex justify-content-between">
                          <div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
                            <img
                              className="w-100"
                              src={integration.image}
                              alt={brandMap[integration.name]}
                            />
                          </div>
                          <Dropdown
                            isOpen={dropdownOpen === integration.id}
                            toggle={() => toggleDropdown(integration.id)}
                          >
                            <DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
                              <i className="fas fa-ellipsis text-gray-800 fs-2"></i>
                            </DropdownToggle>
                            <DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
                              {/* <DropdownItem
                                className="bg-hover-light-info"
                                onClick={() => handleUpdateClick(integration)}
                              >
                                Update
                              </DropdownItem> */}
                              <DropdownItem
                                className="bg-hover-light-info"
                                onClick={() =>
                                  handleDeleteClick(integration.id)
                                }
                              >
                                Deactivate
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="pt-lg-8 pt-4">
                          <CardTitle tag="h2" className="mb-5">
                            {brandMap[integration.name]}
                          </CardTitle>
                        </div>
                      </Card>
                    </div>
                  ))}
              </div>
            </>
          )}
          {integrations.filter((integration) => integration.type === "sms")
            .length > 0 && (
            <>
              <h3 className="text-center">SMS Integrations</h3>
              <div className="row">
                {integrations
                  .filter((integration) => integration.type === "sms")
                  .map((integration, index) => (
                    <div
                      key={integration.id}
                      className="col-md-4 col-12 rounded-2 pb-10 px-5"
                    >
                      <Card className="border-gray-250 border rounded-2 p-8">
                        <div className="d-flex justify-content-between">
                          <div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
                            <img
                              className="w-100"
                              src={integration.image}
                              alt={brandMap[integration.name]}
                            />
                          </div>
                          <Dropdown
                            isOpen={dropdownOpen === integration.id}
                            toggle={() => toggleDropdown(integration.id)}
                          >
                            <DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
                              <i className="fas fa-ellipsis text-gray-800 fs-2"></i>
                            </DropdownToggle>
                            <DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
                              {/* <DropdownItem
                                className="bg-hover-light-info"
                                onClick={() => handleUpdateClick(integration)}
                              >
                                Edit
                              </DropdownItem> */}
                              <DropdownItem
                                className="bg-hover-light-info"
                                onClick={() =>
                                  handleDeleteClick(integration.id)
                                }
                              >
                                Deactivate
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="pt-lg-8 pt-4">
                          <CardTitle tag="h2" className="mb-5">
                            {brandMap[integration.name]}
                          </CardTitle>
                        </div>
                      </Card>
                    </div>
                  ))}
              </div>
            </>
          )}
          {integrations.filter((integration) => integration.type === "email")
            .length === 0 &&
            integrations.filter((integration) => integration.type === "sms")
              .length === 0 && (
              <p className="text-center">No integrations available.</p>
            )}
        </TabPane>
        <TabPane tabId="2">
          <div className="row">
            {brands.map((brand, index) => (
              <div
                className="col-md-4 col-12 rounded-2 pb-10 px-5"
                key={brand.name}
              >
                <Card className="border-gray-250 border rounded-2 p-8">
                  <div className="d-flex justify-content-between">
                    <div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
                      <img
                        className="w-100"
                        src={brand.image}
                        alt={brand.name}
                      />
                    </div>
                    <Dropdown
                      isOpen={dropdownOpen === index + 100}
                      toggle={() => toggleDropdown(index + 100)}
                    >
                      <DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
                        <i className="fas fa-ellipsis text-gray-800 fs-2"></i>
                      </DropdownToggle>
                      <DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
                        <DropdownItem
                          className="bg-hover-light-info"
                          onClick={() => handleActivateClick(brand)}
                        >
                          Activate
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="pt-lg-8 pt-4">
                    <CardTitle tag="h2" className="mb-5">
                      {brand.name}
                    </CardTitle>
                    <CardText className="fw-semibold fs-5 text-gray-700">
                      {brand.description}
                    </CardText>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className="row">
            {smsBrands.map((brand, index) => (
              <div
                className="col-md-4 col-12 rounded-2 pb-10 px-5"
                key={brand.name}
              >
                <Card className="border-gray-250 border rounded-2 p-8">
                  <div className="d-flex justify-content-between">
                    <div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
                      <img
                        className="w-100"
                        src={brand.image}
                        alt={brand.name}
                      />
                    </div>
                    <Dropdown
                      isOpen={dropdownOpen === index + 200}
                      toggle={() => toggleDropdown(index + 200)}
                    >
                      <DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
                        <i className="fas fa-ellipsis text-gray-800 fs-2"></i>
                      </DropdownToggle>
                      <DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
                        <DropdownItem
                          className="bg-hover-light-info"
                          onClick={() => handleActivateClick(brand)}
                        >
                          Activate
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="pt-lg-8 pt-4">
                    <CardTitle tag="h2" className="mb-5">
                      {brand.name}
                    </CardTitle>
                    <CardText className="fw-semibold fs-5 text-gray-700">
                      {brand.description}
                    </CardText>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </TabPane>
      </TabContent>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        className="custom-modal-width"
      >
        <ModalHeader
          className="mb-4 mx-4 mt-2 border-bottom"
          toggle={toggleModal}
        >
          <h3 className="font-weight-bold">
            {isUpdate ? "Update API KEY" : "Add API KEY"}
          </h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                {brandName.username && (
                  <>
                    <Label className="fs-5 fw-bold mb-2">
                      {brandName.usernameName
                        ? brandName.usernameName
                        : "UserName"}
                    </Label>
                    <Input
                      type="text"
                      className="form-control w-100 rounded-1 h-50px mb-5"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </>
                )}
                {brandName.apiKey && (
                  <>
                    <Label className="fs-5 fw-bold mb-2">
                      {brandName.apiKeyName ? brandName.apiKeyName : "API Key"}
                    </Label>
                    <Input
                      type="text"
                      className="form-control w-100 rounded-1 h-50px mb-5"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </>
                )}
                {brandName.domain && (
                  <>
                    <Label className="fs-5 fw-bold mb-2">
                      {brandName.domainName ? brandName.domainName : "Domain"}
                    </Label>
                    <Input
                      type="text"
                      className="form-control w-100 rounded-1 h-50px mb-5"
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    />
                  </>
                )}
                {brandName.fromNumber && (
                  <>
                    <Label className="fs-5 fw-bold mb-2">
                      {brandName.fromNumberName
                        ? brandName.fromNumberName
                        : "From Number"}
                    </Label>
                    <Input
                      type="tel"
                      className="form-control w-100 rounded-1 h-50px mb-5"
                      value={fromNumber}
                      onChange={(e) => setFromNumber(e.target.value)}
                    />
                  </>
                )}
              </FormGroup>
            </Col>
            <Col md={12} className="text-end">
              <Button
                color="secondary"
                className="rounded-1 me-2"
                onClick={testConnection}
                disabled={isLoading}
              >
                {isLoading ? "Testing..." : "Test Connection"}
              </Button>
              <Button
                color="info"
                className="rounded-1"
                disabled={!testSuccess}
                onClick={isUpdate ? handleUpdate : handleSave}
              >
                {isUpdate ? "Update Sender" : "Save Sender"}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default IntegrationsCard;
