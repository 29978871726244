import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  
<svg width={ width || 1583} height={height || 1736} viewBox="0 0 1583 1736" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M70 316H140V184H70C31.3404 184 0 213.548 0 250C0 286.452 31.3404 316 70 316Z" fill={secondary || "#6C7480"}/>
<path d="M70 625H140V493H70C31.3404 493 0 522.548 0 559C0 595.452 31.3404 625 70 625Z" fill={secondary || "#6C7480"}/>
<path d="M70 934H140V802H70C31.3404 802 0 831.548 0 868C0 904.452 31.3404 934 70 934Z" fill={secondary || "#6C7480"}/>
<path d="M70 1243H140V1111H70C31.3404 1111 0 1140.55 0 1177C0 1213.45 31.3404 1243 70 1243Z" fill={secondary || "#6C7480"}/>
<path d="M70 1552H140V1420H70C31.3404 1420 0 1449.55 0 1486C0 1522.45 31.3404 1552 70 1552Z" fill={secondary || "#6C7480"}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M140 1614.29L140.018 1519C140.034 1519 140 1519 140 1519L140.034 1374.33V1229.67V1085V940.334V795.667V651V361.667L140.034 126.643C140.034 6.79743 242.326 0 242.326 0H1479.73C1479.73 0 1583 6.79743 1583 126.643V1614.29C1583 1734.14 1453.82 1736 1453.82 1736H270.821C144.991 1736 140 1614.29 140 1614.29Z" fill={primary|| "#231F20"}/>
<path d="M204 112C204 84.3862 226.386 62.0005 254 62.0005H1470C1497.61 62.0005 1520 84.3863 1520 112V1328C1520 1355.61 1497.61 1378 1470 1378H254C226.386 1378 204 1355.61 204 1328V112Z" fill="white"/>
<text fill={textColor || "white"} xmlSpace="preserve" style={{whiteSpace: 'pre' }} font-family="Arial" fontSize={fontSize || 224} fontWeight="bold" letterSpacing="0em"><tspan x="55%" textAnchor="middle" y="1632.66">{text}</tspan></text>
  
  {/* Image element */}
  <image href={url} x="350" y="208" width="1024" height="1024" />
</svg>


 

    </>
  )
}

export default IconFrame5