import { useState, useEffect, useContext } from "react";
import { Card, CardBody, Modal, ModalBody, Spinner } from "reactstrap";
import ShortLinksHeader from "./shortLinksHeader/index";
import ShortLinksTable from "./shortLinkTable/index";
import AddBulkShortLinks from "./addBulkLinks/addBulkShortLinks";
import ShortLinksAPI from "../../services/http/shortLinks";
import AddNewShortLink from "./addNew";
import Sidebar from "../common/sidebar";
import { ShortLinkContxt } from "./shortLinkContxt";
import { AuthContxt } from "../../store/authContxt";
import Onboarding from '../../pages/onBoarding';
import { useLocation } from "react-router-dom";

const ShortLinksMain = () => {
  const { profile } = useContext(AuthContxt);
  const location = useLocation()
  useEffect(()=>{
    setIsModalOpen(Object.keys(profile)?.length  != 0 && (!profile.demoTour1)  && (location?.state?.openModal == false ? false : true))
  },[profile])
  const toggleModal = () => setIsModalOpen(prev=>!prev);
  const [isModalOpen, setIsModalOpen] = useState(  Object.keys(profile)?.length != 0 && (!profile.demoTour1 )  && (location?.state?.openModal == false ? false : true));
  const auth_token = localStorage.getItem("auth_token");

  const { tags, shortLinks, setShortLinks, loading, setNotLinksScreen } =
    useContext(ShortLinkContxt);
  const { fetchQuota } = useContext(AuthContxt);

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [addBulk, setAddBulk] = useState(false);
  const [isAddNewLinkOpen, setIsAddNewLinkOpen] = useState(false);
  const addBulkClose = () => setAddBulk(false);
  const addBulkShow = () => setAddBulk(true);

  const shortlinksHandler = (filters) => {
    try {
      setFiltersLoading(true);
      const { starred, ...rest } = filters;
      ShortLinksAPI.filteredShortLinks(auth_token, {
        ...rest,
        ...(starred ? { starred } : {}),
        tags: filters.tags.join(","),
      })
        .then(({ data }) => {
          setShortLinks(data.resultData);
          data.resultData?.length < 1 && setNotLinksScreen("searchFound");
        })
        .catch((error) => {
          console.log("Error while fetching shortlinks: ", error);
        })
        .finally(() => {
          setFiltersLoading(false);
        });
    } catch (e) {
      console.log("Error while fetching filtered data : ", e.message);
    }
  };

  useEffect(() => {
    fetchQuota();
  }, []);

  return (
    <div className="w-full d-flex">
      <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
        <Sidebar />
      </div>
      <div className="customDashboardSize">
        <div className="d-flex flex-column w-100 flex-root app-root">
          <div className="app-page flex-column flex-column-fluid">
            <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
              <div className="app-main flex-column flex-row-fluid">
                <Card className="card card-flush rounded-sm-0">
                  <ShortLinksHeader
                    tags={tags}
                    shortlinksHandler={shortlinksHandler}
                    addBulkShow={addBulkShow}
                    loading={filtersLoading}
                    setIsAddNewLinkOpen={setIsAddNewLinkOpen}
                    count={shortLinks?.length}
                  />
                  <CardBody
                    className="card-body px-6 px-lg-10 pt-0 overflow-x-auto "
                    style={{ minHeight: "200px" }}
                  >
                    {loading ? (
                      <div
                        style={{
                          height: "70vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner />
                      </div>
                    ) : (
                      <ShortLinksTable
                        shortLinks={shortLinks}
                        setIsAddNewLinkOpen={setIsAddNewLinkOpen}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <AddNewShortLink
          isAddNewLinkOpen={isAddNewLinkOpen}
          setIsAddNewLinkOpen={setIsAddNewLinkOpen}
        />

        <AddBulkShortLinks addBulk={addBulk} addBulkClose={addBulkClose} />
      </div>
      <div className='onboarding-styles'>
      <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom-modal">
       
       <ModalBody>
         <Onboarding closeModal = {()=>{setIsModalOpen(false)}}/>
       </ModalBody>
      
     </Modal>
     </div>
    </div>
  );
};

export default ShortLinksMain;
