import React from 'react'

const IconFrame5 = ({ primary,  secondary, fontSize, text, textColor, type, url, width, height, minFontSize, maxFontSize }) => {
  return (
    <>
  

<svg width={width || 1562} height={height || 1995} viewBox="0 0 1562 1995" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_175_7450)">
<path d="M780.5 1698L646.699 1560.75L914.301 1560.75L780.5 1698Z" fill={primary || "#0E1B21"}/>
<path d="M225 35.5H1337C1441.66 35.5 1526.5 120.342 1526.5 225V1337.21C1526.5 1441.86 1441.66 1526.71 1337 1526.71H993.166H770.5H559.086H225C120.342 1526.71 35.5 1441.86 35.5 1337.21V225C35.5 120.342 120.342 35.5 225 35.5Z" fill={secondary || "#6C7480"} stroke={primary || "#0E1B21"} stroke-width="71"/>


<text fill={textColor || "white"} xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="Arial" fontSize={fontSize || 208} fontWeight="bold" letterSpacing="0em">
      <tspan x="50%" textAnchor='middle' y="1979.77">{text}</tspan>
    </text>
</g>
<defs>
<clipPath id="clip0_175_7450">
<rect width="1562" height="1995" fill="white"/>
</clipPath>
</defs>
<image href={url} x="269" y="269" width="1024" height="1024" />
</svg>
 

    </>
  )
}

export default IconFrame5